export default {
  breakpoints: ["40em", "52em", "64em"],
  colors: {
    text: "#333",
    background: "#fff",
    primary: "#4C7581",
    secondary: "#ff6347",
    grey: "#6C6C6C",
    lightGrey: "#CBCBCB",
    darkGrey: "#828181",
    textGrey: "#606060",
    darkTextGrey: "#707070",
    jade: "#005864",
    earth: "#ECEAE6",
  },
  fonts: {
    body: "Helvetica Neue",
    heading: "Playfair Display",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 300,
    bodyHeavy: 400,
    lessBold: 500,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
    title: 1,
  },
  shadows: {
    button: "0px 3px 6px #00000029",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  buttons: {
    primary: {
      bg: "white",
      color: "grey",
      borderRadius: 8,
      boxShadow: "button",
      py: 3,
      fontFamily: "body",
      minWidth: 160,
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  text: {
    default: {
      fontWeight: "body",
      fontFamily: "body",
      color: "textGrey",
    },
    heading: {
      fontFamily: "body",
    },
    headerLink: {
      color: "white",
      position: "relative",
      textDecoration: "none",
      fontSize: 2,
      fontFamily: "body",
      cursor: "pointer",
      ml: 4,
      pb: 2,
    },
  },
  variants: {
    card: {
      textAlign: "center",
      borderRadius: 8,
      minHeight: 200,
      px: 5,
      py: 4,
      backgroundColor: "primary",
    },
    serviceCard: {
      borderRadius: 6,
      backgroundColor: "earth",
      py: 4,
      px: 3,
    },
    testimonialCard: {
      borderRadius: 6,
      backgroundColor: "earth",
      p: 3,
    },
    stack: {
      bg: "transparent",
      textAlign: "center",
    },
    circle: {
      borderRadius: "50%",
      width: 80,
      height: 80,
      bg: "primary",
      mx: "auto",
    },
  },
}
